
const searchMixin = {
    methods: {
       reset(){
       
        for (let key in this.searchInfo) {
            if (key === "page") {
                this.searchInfo.page=1
            }else if(key==="size"){
                this.searchInfo.size=10
            } else {
                this.searchInfo[key] = "";
            }
        }
        this.getDataList()
       }
    },
    
  
}

export { searchMixin };
