<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="活动名称"> </el-table-column>
			<el-table-column label="类型">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 5">线上活动</span>
					<span v-if="scope.row.type == 6">线下活动</span>
				</template>
			</el-table-column>

			<el-table-column prop="start_time" label="活动开始时间">
			</el-table-column>
			<el-table-column prop="end_time" label="活动结束时间"></el-table-column>
			<el-table-column label="显示首页">
				<template slot-scope="scope">
					<span v-if="scope.row.is_banner == 1">显示</span>
					<span v-if="scope.row.is_banner == 0">不显示</span>
				</template>
			</el-table-column>
			<el-table-column label="审核状态">
				<template slot-scope="scope">
					<span v-if="scope.row.status == 0">草稿</span>
					<span v-if="scope.row.status == 1">审核通过</span>
					<span v-if="scope.row.status == 2">审核未通过</span>
					<span v-if="scope.row.status == 3">审核中</span>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="toEdit(scope.row)"
						>编辑</span
					>
					<span class="pointer margin_left_10" @click="toDelete(scope.row)"
						>删除</span
					>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { destroyactivity } from "@/api/article";

export default {
	props: ["tableData"],
	components: {},
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		toEdit(row) {
			this.$router.push({
				path: "/message/addEvent",
				query: { id: row.id, type: "detail" },
			});
		},
		toDelete(row) {
			this.$confirm("此操作将删除该条活动, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					destroyactivity({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
